<template>
  <v-dialog v-model="internalOpen" persistent width="600">
    <div
      style="background-color: #ffffff; padding: 0 1em 2em; position: relative"
    >
      <div
        class="py-4"
        style="
          width: 100%;
          background-color: inherit;
          position: sticky;
          top: 0;
          z-index: 10;
        "
      >
        <v-btn
          style="
            min-width: unset !important;
            min-height: unset !important;
            width: 32px !important;
            height: 32px !important;
          "
          class="px-4 py-4"
          @click.stop="internalOpen = false"
          text
          rounded
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-form ref="form" @submit.prevent="submit" class="otp-code mx-auto">
        <h2 class="my-0 text-center text-uppercase text-h5 font-weight-bold">
          Confirmación de código
        </h2>
        <p class="my-0 text-center mt-2 text-subtitle-1">
          {{ mensaje }}
        </p>
        <div class="my-4 mx-auto">
          <v-otp-input
            v-model="form.code"
            length="6"
            outlined
            style="width: 100%"
            :disabled="request.isLoading"
            @keydown.enter="submit"
            @finish="submit"
          />
        </div>
        <div
          class="d-flex justify-space-betweeen flex-wrap flex-md-nowrap"
          style="gap: 16px"
        >
          <v-btn
            class="flex-grow-1 flex-shrink-1"
            color="secondary"
            :disabled="request.isLoading"
            @click.stop="internalOpen = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="flex-grow-1 flex-shrink-1"
            color="primary"
            type="submit"
            :loading="request.isLoading"
          >
            Confirmar
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-dialog>
</template>
<script>
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
  isResponseSuccesful,
} from "@/utils/loadable";
import jwtDecode from "jwt-decode";
import { mapActions } from "vuex";
import { isNil } from "lodash";

export default {
  name: "Confirm2FA",
  emits: ["on-visibility-change"],
  props: {
    isOpen: { type: Boolean },
    email: { type: String },
    servicio: { type: Function },
    mensaje: {
      type: String,
    },
  },
  data: () => ({
    form: {
      code: null,
    },
    internalOpen: false,
    request: createLoadable(null),
  }),
  methods: {
    ...mapActions(["setAuth"]),
    submit() {
      const code = this.form.code;
      if (isNil(code)) {
        this.pushAppMessage({
          type: "error",
          message: "Ingrese el código.",
        });
        return;
      }

      if (!/^(\d){6}$/.test(code)) {
        this.pushAppMessage({
          type: "error",
          message: "Formato de código no válido.",
        });
        return;
      }

      this.verify2faCode(code);
    },
    async verify2faCode(code) {
      toggleLoadable(this.request);
      const { data } = await this.servicio({
        code,
        email: this.email,
      });
      setLoadableResponse(this.request, data, { showAlertOnSuccess: true });

      if (!isResponseSuccesful(data)) return;

      const token = data.data.token;
      const { user } = jwtDecode(token);
      this.setAuth(data.data);

      if (user?.two_factor_status) {
        return this.$router.push({ name: "2fa" });
      }

      if (this.haveRole("ROLE_PROVEEDOR"))
        localStorage.setItem("unidades", "false");

      this.$router.push({ name: "dashboard" });
    },
  },
  watch: {
    isOpen(value) {
      if (isNil(value)) return;

      this.internalOpen = value;
    },
    internalOpen(value) {
      this.$emit("on-visibility-change", value);
    },
  },
};
</script>
<style>
.otp-code {
  width: 100%;
}

@media (min-width: 600px) {
  .otp-code {
    width: 80% !important;
  }
}
</style>
